@import '../../../../scss/theme-bootstrap';

.gnav-cart {
  @include breakpoint($landscape-only) {
    height: 100%;
  }
  &__link {
    @include typography-link-text;
    font-size: $nav-label-font-size;
    color: $color-core-black;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    svg.gnav-cart-icon-show {
      .site-header-formatter--large-logo &,
      .elc-user-state-logged-in & {
        display: inline-block;
        @include breakpoint($landscape-only) {
          display: none;
        }
      }
    }
    .icon--bag {
      height: $nav-icon-height;
      width: $nav-icon-width;
      fill: $color-core-black;
      .site-header-formatter--large-logo &,
      .elc-user-state-logged-in & {
        display: none;
      }
      .site-header-formatter--sticky & {
        display: inline-block;
      }
      @include breakpoint($landscape-only) {
        display: none;
        .site-header-formatter--sticky & {
          display: none;
        }
      }
    }
    &.site-utils__link {
      margin: 0;
      padding: 0;
      &:hover {
        color: $color-core-black;
      }
    }
    .cart--bracket {
      display: none;
      .site-header-formatter--large-logo &,
      .elc-user-state-logged-in & {
        display: inline-block;
      }
      .site-header-formatter--sticky & {
        display: none;
      }
      @include breakpoint($landscape-only) {
        display: inline-block;
        .site-header-formatter--sticky & {
          display: inline-block;
        }
      }
    }
  }
  &__qty {
    position: relative;
    display: inline-block;
    top: 2px;
    @include breakpoint($landscape-only) {
      top: 0;
    }
    .site-header-formatter--large-logo &,
    .elc-user-state-logged-in & {
      top: 0;
    }
    .site-header-formatter--sticky & {
      top: 2px;
      @include breakpoint($landscape-only) {
        top: 0;
      }
    }
  }
  &__label {
    display: none;
    .site-header-formatter--large-logo &,
    .elc-user-state-logged-in & {
      display: inline-block;
    }
    .site-header-formatter--sticky & {
      display: none;
    }
    @include breakpoint($landscape-only) {
      display: inline-block;
      .site-header-formatter--sticky & {
        display: inline-block;
      }
    }
  }
  &__label-mobile {
    .site-header-formatter--large-logo &,
    .elc-user-state-logged-in & {
      display: none;
    }
    @include breakpoint($landscape-only) {
      .site-header-formatter--large-logo &,
      .elc-user-state-logged-in & {
        display: inline-block;
      }
    }
  }
  &__overlay {
    .cart_overlay {
      top: 15px;
    }
  }
  &.site-utils__item--cart {
    position: relative;
    top: 0;
    #{$rdirection}: 0;
    display: inline-block;
    .cart_overlay {
      .cart-confirm-wrapper {
        margin-top: 15px;
        @include breakpoint($landscape-only) {
          margin-top: 5px;
        }
      }
    }
  }
}
